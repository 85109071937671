body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.col2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cast {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  gap: 0.5rem;

  padding: 1rem;
  border: 1px solid #e8e8e8;
}

.headcast {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.headtable {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;

  padding: 1rem;
  border: 1px solid #e8e8e8;
}
.castchild {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.genres {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.notification {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.wrap_notification {
  display: flex;

  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #e8e8e8;
  flex-wrap: wrap;
}
/* custom scroll bar */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #333333;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.login-card {
  display: flex;
  flex-direction: column;

  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e8e8e8;
  width: 30%;
}
.login-card-header {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.login-card-header h1 {
  font-size: 1.2rem;
  margin: 0;
  font-weight: 500;
}

.notification_card {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .login-card {
    width: 80%;
  }
  .notification_card {
    width: 100%;
  }
}
